
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700;900&family=Roboto:wght@300;400&display=swap');
* {
    margin: 0;
    padding: 0;
    border: none;
  }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

  ul{padding: 0; margin: 0; list-style: none;}

  .quickActionButton ul li, .quickActionButton ul li::before, a{ transition: all 500ms cubic-bezier(.17,.81,.25,.87);}
 
  .shortmenu li{transition: all 350ms ease-in;}
  .shortmenu li, .shortmenu li::before, .shortmenu li a,   .shortmenu li i, .shortmenu li span, .shortmenu li span::before{ transition: all 350ms cubic-bezier(.8,.01,.98,.55);}
  .sublinks, .sublinks div,  .sublinks::after, .sublinks::before{transition: all 300ms cubic-bezier(.14,.71,.48,.96);}

  button:focus, a:focus{outline: none;}
  a{text-decoration: none;}
  ul li a{display: inline-block;}
  
  body {
    font-size: 14px;
    background-color: aquamarine;
    font-family:  'Roboto', sans-serif;
    font-weight: 400;
    overflow: hidden;
  
  }

  h1, h2, h4, h5, h3{
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
  }

  h4{font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
   
  }

 /* Bg layer1 */
  .homebg{background: rgb(14,22,117);
    background: linear-gradient(32deg, rgba(14,22,117,1) 0%, rgba(81,41,159,1) 100%); height: 100vh;  text-align: right;}
  .homebg img{height: 100vh; max-width: 100vw; filter: blur(85px); }
  /* bannerTextOutLIne  */

  .bannerText{width: 100%; }

.bannerTextOutLIne{position: absolute; z-index: 2; 
  width: 100%; height: 100%;
background: url('./cssimg/expoutline.svg') no-repeat center;
background-size: 75% auto; top: 0;
}
/* 3d Text */
.cube {
  height: 150px;
  width: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 1s;
  transform: translateZ(-75px);
  transform-origin: center center;
  animation:bannertext 20s linear infinite;
}
.cube__face {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
backface-visibility: hidden;
height: 150px;

}
.cube__face--front{ transform: rotateX(  0deg) translateZ(75px); }
.cube__face--back{ transform: rotateX(-180deg) translateZ(75px); }
.cube__face--top{ transform: rotateX( 90deg) translateZ(75px); }
.cube__face--bottom{ transform: rotateX(-90deg) translateZ(75px); }
@keyframes bannertext {
0%{ transform: translateZ(-75px) rotateX(0deg);}
23%{ transform: translateZ(-75px) rotateX(0deg);}
25%{ transform: translateZ(-75px) rotateX(90deg);}
48%{ transform: translateZ(-75px) rotateX(90deg);}
50%{ transform: translateZ(-75px) rotateX(180deg);}
73%{ transform: translateZ(-75px) rotateX(180deg);}
75%{ transform: translateZ(-75px) rotateX(270deg);}
98%{ transform: translateZ(-75px) rotateX(270deg);}
100%{ transform: translateZ(-75px) rotateX(360deg);}
}


.sidBG{ width:350px; background: #f2f2f2; }

.animateSider{animation: sidebg 300ms ease-in;}
@keyframes sidebg{ 
0%{ width:0px;}
100%{width:330px;}
 }

  /* main Wraper */
.mainwraper{
position:absolute; left: 40px; top: 40px;
width: calc(100% - 80px);
height: calc(100vh - 80px);
z-index: 3;
display: flex;
}

.sideDeheader{display: flex; flex-direction:column; min-width:250px; height: 100%; }
.linkefect{background: rgba(255, 255, 255, 0.12); box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08); border: 0;

}
.sideDeheader .logosec{ height: 55px;}

.logosec{padding-bottom: 10px;}
.logo{ width: auto; height: 37px; left: 15px; position: relative; top: 9px;  }

.menubutton{height: 55px; width: 55px; border-radius: 100%; z-index: 4; left:0px; top:0px; position: relative;
  background: rgba(255, 255, 255, 0.14); text-align: center; transition: all 300ms ease-in-out;   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08); overflow: hidden; }
  .page .menubutton{background: #fff;}
.menubutton span{ display: block; width:18px; height: 1px; transition: all 300ms ease-in-out; background-color: #fff; margin:4px auto;}
.page .menubutton span{ background-color: rgb(137, 137, 137);}
.page .menubutton:hover span ,.page .menubutton.opened span{background-color: #fff;}
/* new css */
.menubutton::after, .menubutton::before{ height: 56px; width: 56px;  content: '';  position: absolute;   transform: scale(0.0); 
 border-radius: 100%;   left: 50%; top: 50%; margin: -28px 0px 0px -28px; z-index: -1;}
.menubutton::after{background-color: rgba(255, 255, 255, 0.20); transition: all 400ms cubic-bezier(.52,1.8,.49,-0.77); }
  .menubutton::before{ background: rgb(255,47,97);
   background: linear-gradient(22deg, rgba(255,47,97,1) 0%, rgba(255,155,54,1) 100%);  transition: all 200ms ease-in; }

   .menubutton:hover::after, .menubutton:hover::before{ 
     transform: scale(1.0); 
 } 
.menubutton.opened::before{ transform: scale(1.0);}
.menubutton:hover, .menubutton.opened{ box-shadow:0px 0px 30px rgba(255,47,97,0.4); }
.menubutton.opened{left:30px; top:20px;}
.menubutton.opened span{margin: auto; margin-top: -1px;}
.menubutton.opened span:nth-child(1){ transform: rotate(45deg);}
.menubutton.opened span:nth-child(2){visibility: hidden; height: 0px;}
.menubutton.opened span:nth-child(3){ transform: rotate(-45deg);}


/* mainmenu */
.mainmenu{background-color: #f3f3f3;
 position: absolute;
 width: 250px;
 box-shadow:5px 5px 20px rgba(0, 0, 0, 0.20);
 top: 0; left:-400px;
 border-radius: 9px;
 z-index: 2;
 height: 100%; transition: all 300ms ease-in-out;}
 .mainmenu ul{padding: 100px 30px 30px 30px;}
 .mainmenu ul li{border-top:1px solid rgba(0, 0, 0, 0.1); border-bottom:1px solid rgba(255, 255, 255, 1);}
   .mainmenu ul li a{ padding: 10px 0px; padding-left: 5px;
     font-size: 16px; display: block; font-weight: 400; color: grey; width: auto; }
     .mainmenu ul li:hover a{color:rgba(255,47,97,1); padding-left: 10px; }

     .mainmenu ul li:nth-child(1){border-top: 0px;}
     .mainmenu ul li:nth-last-child(1){border-bottom: 0px;}

     .mainmenu.opened{left:0px;}

     /* mainmenu END */

.sideDeheader .line{ flex-grow: 1; position: relative;}
.sideDeheader .line::after{position: absolute;
  left: 28px;
  content: ''; height: 100%; width: 1px; background-color:  rgba(255, 255, 255, 0.25);}
  .page.sideDeheader .line::after{background-color: rgba(0, 0, 0, 0.12);}
.sideDeheader .copyright{height: 15px; padding-top: 10px;
  font-size: 14px;
  color: #cecece; }
  .page.sideDeheader .copyright{color: #9f9f9f; }

  .shortmenu li.active i {
    line-height: 44px;
 
    display: block; 
}
.page .shortmenu li:hover i {
color: #fff;
}

.shortmenu li.active a{
   margin: 0px 3px;}

  /* shortmenu */


  .shortmenu a{ display: block;}
  .shortmenuBox{  position: relative;  width: auto; padding: 0px 3px; margin: 5px 0; margin-bottom: 0px; }
 
  .shortmenu li{width: 48px;
    height: auto;
    max-height: 48px;
    display: block;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.12);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
     }
     .page .shortmenu li{ background-color: #fff;}
   
     .shortmenu li.active{max-height: 1000px;  padding: 5px 0px;     overflow: inherit;}
     .shortmenu li::before{
       content: '';
       background: rgb(38,124,197);
       background: linear-gradient(39deg, rgba(38,124,197,1) 17%, rgba(66,226,254,1) 77%);
      height:100%;
      width: 48px;
      position: absolute;
      border-radius: 8px;
      left: 0px; top:0px;
      opacity: 0;
    
     }
   
     .shortmenu li a{position: relative; visibility: hidden; border-top:1px solid rgba(255, 255, 255, 0.20);}
   
     .shortmenu li a:nth-child(1){ visibility: visible; border-top:0px;}
     .shortmenu li.active a, .shortmenu li:hover a{ visibility: visible;}

     .shortmenu li:hover, .shortmenu li.active{
      box-shadow: 0px 0px 30px rgba(0, 255, 255, 0.7);}
      .shortmenu li:hover::before, .shortmenu li.active::before{
        opacity: 1;
      }

      .shortmenu li span{ position: absolute;
        left: 48px;
        color: #fff;
        white-space: nowrap;
        font-size: 14px;
        top: 16px;
        display: block;
        visibility: hidden; opacity: 0; }
        .page .shortmenu li span{ color:rgb(136, 162, 185); ;}
        .shortmenu a span::before{ position:absolute;
          height: 1px;
          top: 50%;
          left: 0px;
          width: 0px;
          background-color:  rgba(255, 255, 255, 0.25);
          content:'';}
         .page .shortmenu a span::before{ background-color:rgba(136, 162, 185, 0.5); ; }
        .shortmenu a:hover span{visibility: visible;
          left: 65px;
          opacity: 1;}

          .shortmenu a:hover span::before{
            left: -17px;
            width: 17px;
          }
      
      .shortmenu li i{font-size: 35px; color:#fff;
      line-height: 48px;  display: block; text-align: center;
      }
      .page .shortmenu li i {    color: rgb(136 162 185);}
      .shortmenu li.active i, .page .shortmenu li a:hover i{ color:#fff;}
     
      .shortmenu li a.active i, .shortmenu li.active a:hover i{ transform: scale(0.85);
        background-color: rgba(0,0,0,0.1); border-radius: 100%;}
        
    
/* contentBox */
.contentBox{ height: 100%; flex-grow: 1;
align-items: center; display: flex;
animation: pageTRans 500ms cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

}
@keyframes pageTRans{
0%{
  transform: translateX(50px) ;
  opacity: 0;
}
100%{
  transform:translateX(0px) ;
  opacity: 1;
}

}


.contentText{ display:block; width: 100%;  align-items: center;  
  max-height: calc(100vh -    130px);
  height: auto;
 
  margin-bottom: 70px;}
.bennerImage img{ width: auto; height: calc(100vh - 130px); 
  position: relative; left: -75px;}
.innerpage .bennerImage{display: flex;
  height: 300px;
 min-width: 300px;
 width: 300px;
  background: #ffca70;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: relative;
  left: -50px;
  margin-left: -40px;}
  .transBg{background-color: transparent !important;}
  .scaleanimtion{ transform: scale(1.0); opacity:1;  animation:scaleanim 1s cubic-bezier(.43,.31,.46,.99) forwards;}
  .innerpage .bennerImage img{ animation-delay: 300ms; transform: scale(0.0); opacity:0;}
@keyframes scaleanim {
  0%{ transform: scale(0.0); opacity: 0;}
  100%{transform: scale(1.0); opacity: 1; }
}
  .innerpage .bennerImage img{ height: 65vh; margin-right: auto;  margin-left: auto; left: 0;}
  .bannerText h1{font-size: 90px; font-weight: 900; color: #fff; }
  .bannerText p{font-size: 35px; font-weight: 300; color: #fff; }
  .contentText h1{ color: #fff; font-size: 2.5rem;  padding-bottom:10px;  position: relative; }

  .contentText h1 i{vertical-align: middle;}
  .contentText p{  color: #fff; text-align: justify; line-height: 1.5; letter-spacing: 1px; margin-top: 5px;}
  .customPara{
    max-width: 98%; position: relative;
    display: block;
    padding-left: 0px;
    padding-right: 15px;
    padding-top: 2%;
    padding-bottom:2%;
}

.leftline{
  display: block; padding-left: 40px; position: relative; height: auto ;
}

.leftline::before {
  content: '';
  position: fixed;
  height: 100%;
  width: 1px;
  top: 0;
  margin-left: -20px;
  background-color: #ffffff30;
}

.breadcum::before{top: 0; bottom: inherit;}
.breadcum{width: 95%; display: block;   position: relative; padding-top: 10px; }
.sublinks{ display:inline-block; position: relative; border-radius:20px; width: auto;  height:40px; max-width:40px; font-size: 20px;
   line-height:40px; min-width: 40px; text-align: center; margin: 0px 5px; background-color: rgba(0, 0, 0, 0.2); color: #fff;  overflow: hidden;}
   .sublinks::after{ content: ''; left: 0; box-shadow: 0px 0px 30px rgb(255 47 127 / 0%);
    z-index: -1; position: absolute; height: 101%; width: 101%; transform: scale(1.0); background: rgba(255,47,127,1);
    background: linear-gradient(90deg, rgba(255,47,127,1) 0%, rgba(255,155,54,1) 100%);  border-radius:20px; opacity: 0; }
.sublinks div{ font-size: 0px; width: 100%; 
  text-align: center; 
  display:initial; padding: 0px 0px; vertical-align: top;} 
.sublinks:hover, .sublinks.active{max-width:400px;  box-shadow: 0px 0px 30px rgb(255 47 127 / 70%); background-color: rgba(0, 0, 0, 0); }
.sublinks:hover div, .sublinks.active div{font-size: 15px;  padding: 0px 15px;  }
.sublinks:hover::after, .sublinks.active::after{ opacity: 1;  }
.sublinks i{ font-size: 25px; line-height:40px;  vertical-align: middle;  }

.contentScroll{display: flex; flex-direction: column;   max-height: calc(100vh - 130px);
  height: auto; }
.contentScroll .customPara{ overflow: auto; flex-grow: 1;   scrollbar-color:rgba(255, 255, 255, 0.5) rgba(1, 25, 92, 0.08);
scrollbar-width: thin;   mask-image: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 4%, rgba(0,0,0,1) 96%, rgba(0,0,0,0) 100%);}


  /* quickActionButton */

  .quickActionButton{position: absolute;  right: 0; bottom: 0; z-index: 4;}

  .quickActionButton ul{list-style: none; padding: 0; margin: 0;}
  .quickActionButton ul li{ 
    position: relative;
    display: inline-block;
    width:auto;
    min-width: 46px;
    margin: 0px 5px;
    text-align: center;
    line-height: 46px;
    height: 46px;
    vertical-align: bottom;
    border-radius:28px;
    background: rgba(255, 255, 255, 0.27);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  .quickActionButton ul li::before{
    content: '';
    position: absolute;
    border-radius: 100%;
    height: 10px;
    width: 10px;
    top: 50%;
    left: 50%;
    background: #ffd5d5;
    background: linear-gradient(#fff 0%, #ffd5d5 100%);
    margin: -5px 0px 0px -5px;
transform: scale(0.0);
z-index: -1;
  }
  .quickActionButton ul li:hover{box-shadow: 0px 0px 40px rgba(255, 255, 255, 0.74);}

  
  .quickActionButton ul li:hover::before{
    transform: scale(20.0);
  }
  .quickActionButton ul li{ color: #fff;}
  .quickActionButton ul li:hover {color: #f17953;}
  .quickActionButton ul li span{
    font-weight: 700;
    font-size: 14px;
 letter-spacing: 1px;
padding:0px 25px
}

.quickActionButton ul li i{font-size: 25px;
  line-height: 46px;  }

  .backeButton{ position: absolute; right: 0; top: 0; width: 45px;
     height: 45px; text-align: center; display: inline-block; line-height: 45px; background-color: rgba(0, 0, 0, 0.5); color: #fff;}
     .backeButton i{line-height: 45px;}


.radibutton li{position: relative; position: relative; margin-bottom: 10px;}
.radibutton li label{
  color: #6e6aae;
  padding-left: 28px;
  display: block;
  position: relative;
  line-height: 20px !important;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.radibutton.block li label, .radibutton.buttonstyle li label{ border-right: 0px; padding-right: 0px; margin-right: 0px;}

.radibutton li input[type=radio] {
  position: absolute;
  opacity:0;
  cursor: pointer;
}
.radibutton li:nth-last-child(1) label{ border-right: none; padding-right: 0px; margin-right: 0px;}
.row div:nth-last-child(1) .formContent{ margin-bottom: 0px;}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background: rgba(255, 255, 255, 0.37);
  border-radius: 50%;
  border: 0px solid transparent;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
label input:checked ~ .checkmark, label input:hover ~ .checkmark{

  box-shadow: 0px 0px 13px #ff3f74;
    border: 0px solid rgba(0,0,0,0.1);
    background: rgb(255,47,127);
    background: linear-gradient(
0deg
, #ff9b36  0%, #ff2f7f 100%);
  
}

.radibutton.buttonstyle label input:checked ~ .checkmark, label input:hover ~ .checkmark{
  box-shadow: 0px 0px 13px #ff3f74;
    border: 0px solid rgba(0,0,0,0.1);
    background: rgb(255,47,127);
    background: linear-gradient(-90deg, #ff9b36  0%, #ff2f7f 100%);
}

.radibutton.buttonstyle li label{ padding: 10px 21px !important;
  margin-right: 10px !important; font-size: 14px;}
.radibutton.buttonstyle li .checkmark{ height: 100%; height: 100%;     height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 25px; }

  ::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(1, 25, 92, 0.08); border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
  width: 1px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
border-radius: 4px;

}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(255,47,97);
  background: linear-gradient(0deg, rgb(255, 246, 247) 0%, rgba(255,155,54,1) 100%);
}

/* form */
.formContent {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ffffff30;
  color: #fff;
}
.flex{display: flex;}

.formContent{font-size: 16px;}
.formContent label{ color: #fff; padding-right: 10px;
   margin-right: 10px;
    border-right: 1px solid #ffffff30;
  }
  .formContent input{flex-grow: 1; background-color: transparent; color: #fff; }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border:none;
    -webkit-text-fill-color: #fff;
   box-shadow: 0 0 0px 1000px #fff0 inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .formContent ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(255, 255, 255, 0.5);
    opacity: 1; /* Firefox */
  }
  
  .formContent:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(0, 255, 255, 0.7);
  }
  
  .formContent::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(0, 255, 255, 0.7);
  }

  input{font-size: 16px;}
  input:hover, input:focus, input:active{
    background-color: transparent; box-shadow: none; border: 0; outline: none;
  }

  /* ulList */

  .ulList{
list-style: none;
  }
  .ulList li{ position: relative;  margin-bottom:10px; margin-top: 10px;}
  .ulList li:nth-last-child(1){ margin-bottom:0px; }
  .ulList li::before, .ulList li::after{position:absolute; content: ''; height: 10px; width: 10px; left: -24px; top: 5px; border-radius: 100%; }
  .ulList li::before{ z-index: 2;  background: rgba(255,47,127,1);
    background: linear-gradient(90deg, rgba(255,47,127,1) 0%, rgba(255,155,54,1) 100%);
  box-shadow: 0px 0px 0px 5px rgba(0,0,0, 0.2);
animation: shadow 2s linear infinite;
  }
  .ulList li::after{ border:0px solid rgba(255, 255, 255, 0.3); animation: shadow2 1.5s linear infinite; height: 24px; width: 24px; left: -31px; top:-2px;   }
  @keyframes shadow{
0%{box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.2);}
25%{ box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.2);}
75%{box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);}
100%{box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.0);}

  }


  @keyframes shadow2{
    0%{box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.2); height: 10px; width: 10px; left: -24px; top: 5px; pacity: 1;}
    25%{ box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.2);}
    75%{box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2); opacity: 1;}
    100%{box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.0); height: 24px; width: 24px; left: -31px; top:-2px; pacity: 0;}
    
      }

@media only screen and (max-width: 720px) {
  .mainwraper {
    left: 10px;
    top: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
}
.bannerTextOutLIne {
  width:calc(100% - 50px) ;
  background: url('./cssimg/expoutline2.svg') no-repeat center;
  background-size: auto 75%;
 right: 0;
}
.sideDeheader .copyright {
display: none;
}

.menubutton {
  height: 40px;
  width: 40px;
  min-width: 40px;
}
.menubutton span{
  width: 15px;
}

.page .shortmenu li i {
  color: #fff;
}
.page .shortmenu li {
  background: rgba(255, 255, 255, 0.12);
}
.page .menubutton {
  background: rgba(255, 255, 255, 0.12);
}
.page .menubutton span {
  background-color: #fff;
}
.page.sideDeheader .line::after {
  background-color: rgba(255, 255, 255, 0.25);
}
.formContent {
  margin-bottom: 10px;
}

.sideDeheader {
  max-width: 50px;
  min-width: auto;
}
.sideDeheader .logosec{
  height: 50px;
  display: flex;
  align-items: center;
}
.logo {
  height: 26px;
  position: relative;
  top: 0px;
}
.sideDeheader .line::after {
  left: 20px;
}
.sideDeheader .line:nth-last-child(2) {
 display: none;
}
.shortmenuBox {
  padding-left: 0px;
}
.shortmenu li i {
  font-size: 28px;
  line-height: 40px;
}
.shortmenu li::before {
  width: 40px;
  border-radius: 4px;
}
.shortmenu li {
  width: 40px;
  max-height: 40px;
  border-radius: 4px;
}
.shortmenu li.active a {
  margin: 0px 0px;
}
.shortmenu li.active i {
  line-height: 40px;
}
.contentBox {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: pageTRanss;
}
.bannerText h1 {
  font-size: 34px;
}
.bannerText p {
  font-size: 16px;
}
.bennerImage img {
  height: auto;
  width: 200px;
  min-height: 200px;
  left: 0px;
  top: -40px;
}
.bannerText {
  text-align: center;
}
/* 3d Text */
.cube {
  height: 70px;
  transform: translateZ(-35px);
}
.cube__face {
height: 70px;
}
.cube__face--front{ transform: rotateX(  0deg) translateZ(35px); }
.cube__face--back{ transform: rotateX(-180deg) translateZ(35px); }
.cube__face--top{ transform: rotateX( 90deg) translateZ(35px); }
.cube__face--bottom{ transform: rotateX(-90deg) translateZ(35px); }
@keyframes bannertext {
0%{ transform: translateZ(-35px) rotateX(0deg);}
23%{ transform: translateZ(-35px) rotateX(0deg);}
25%{ transform: translateZ(-35px) rotateX(90deg);}
48%{ transform: translateZ(-35px) rotateX(90deg);}
50%{ transform: translateZ(-35px) rotateX(180deg);}
73%{ transform: translateZ(-35px) rotateX(180deg);}
75%{ transform: translateZ(-35px) rotateX(270deg);}
98%{ transform: translateZ(-35px) rotateX(270deg);}
100%{ transform: translateZ(-35px) rotateX(360deg);}
}
  ::-webkit-scrollbar {width: 1px;}

  .quickActionButton ul li span {
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0px;
    padding: 0px 16px;
}
.quickActionButton ul li i {
  font-size: 18px;
  line-height: 35px;
}
.quickActionButton ul li {
  min-width: 35px;
  line-height: 34px;
  margin: 0px 2px;
  height: 35px;}

.contentBox.innerpage{max-height: 100%; align-items: flex-end;}
.innerpage .bennerImage { height: 10px;  width: 100px; min-width: inherit; background-color: transparent;  align-items: center; justify-content: center; left: 0; margin-left: 0;
}
.contentBox.innerpage .bennerImage img {
  top: 0px;
}
.innerpage .bennerImage img { height: 100px; max-height: 100px; width: auto; min-height: inherit;
}
.contentText h1 { color: #fff;     font-size: 1.2rem; text-align: left;}
.customPara {max-width: 100%;  padding-left: 0px; padding-right: 0px;}

.contentScroll { max-height: calc(100vh - 180px);}

.contentBox.innerpage .contentText {margin-bottom: 0px;}
.leftline {padding-right: 8px;
  padding-left: 8px;}

.contentText p{ letter-spacing: inherit; margin-top: 5px; font-size: 12px; }
.ulList {padding-left: 26px;}

.sublinks {
  height: 30px;
  max-width: 30px;
  font-size: 20px;
  line-height: 30px;
  min-width: 30px;
  margin: 0px 2px;
}
.sublinks:hover div, .sublinks.active div {
  font-size: 10px;
  padding: 0px 10px;
}
.sublinks i {
  font-size: 19px;
  line-height: 30px;
  vertical-align: bottom;
}

.radibutton.flex{display: block;}
.leftline::before{display: none;}
/* Track */
::-webkit-scrollbar-track{
  width:1;
}


.radibutton li label{
  font-size: 14px;
  display: inline-block;
}
.formContent {
  font-size: 14px;
}
input {
  font-size: 14px;
}
h4 {
  font-size: 18px;
}
.row>* {
  padding-right:6px;
  padding-left:6px;
}
.row {
  margin-left: -6px !important;
  margin-right: -6px !important;
}
.sidBG{display: none;}
.shortmenu a:hover span {
  display: none;
}

.formContent label{border-right: none;}
.customPara{
  max-width: 98%; position: relative;
  display: block;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 2%;
  padding-bottom:2%;
}
}
  