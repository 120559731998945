*, ::after, ::before {
    box-sizing: border-box;
}
.row>* {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.row {
 margin-left: -15px; margin-right: -15px;
    display: flex;
    flex-wrap: wrap;
}
.col {
    -ms-flex-preferred-size: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.col-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
}
.col-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 16.666667%;
}
.col-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 25%;
}
.col-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 33.3333333333%;
   
}

.col-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 50%;
}

.col-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 66.666667%;
}



@media (max-width: 575.98px) { .col-xs-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 16.666667%;
}
.col-xs-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 25%;
}
.col-xs-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 33.3333333333%;
   
}

.col-xs-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 50%;
}

.col-xs-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 66.666667%;
}
  }




@media (min-width: 768px) and (max-width: 991.98px) {
    .col-md-12 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 100%;
    }

    .col-md-2 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 16.666667%;
    }
    .col-md-3 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 25%;
    }
    .col-md-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 33.3333333333%;
       
    }
    
    .col-md-6 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 50%;
    }
    
    .col-md-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 66.666667%;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .col-lg-2 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 16.666667%;
    }
    .col-lg-3 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 25%;
    }
    .col-lg-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 33.3333333333%;
       
    }
    
    .col-lg-6 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 50%;
    }
    
    .col-lg-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 66.666667%;
    }
}


@media (min-width: 1200px) {

    .col-xl-2 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 16.666667%;
    }
    .col-xl-3 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 25%;
    }
    .col-xl-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 33.3333333333%;
       
    }
    
    .col-xl-6 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 50%;
    }
    
    .col-xl-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 66.666667%;
    }

}

@media (max-width: 576px){ 
    .col-sm-12 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 100%;
    }
    .col-sm-2 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 16.666667%;
    }
    .col-sm-3 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 25%;
    }
    .col-sm-4 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 33.3333333333%;
       
    }
    
    .col-sm-6 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 50%;
    }
    
    .col-sm-8 {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 66.666667%;
    }
}


