@font-face {
  font-family: 'ews';
  src:  url('fonts/ews.eot?vk6jew');
  src:  url('fonts/ews.eot?vk6jew#iefix') format('embedded-opentype'),
    url('fonts/ews.ttf?vk6jew') format('truetype'),
    url('fonts/ews.woff?vk6jew') format('woff'),
    url('fonts/ews.svg?vk6jew#ews') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ews"], [class*=" ews"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ews' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ewse_whatsapp:before {
  content: "\e90f";
}
.ewse_behance:before {
  content: "\e900";
}
.ewse_call:before {
  content: "\e901";
}
.ewse_digitalmar:before {
  content: "\e902";
}
.ewse_edit:before {
  content: "\e903";
}
.ewse_email:before {
  content: "\e904";
}
.ewse_facebook:before {
  content: "\e905";
}
.ewse_gaphicsdes:before {
  content: "\e906";
}
.ewse_getquote:before {
  content: "\e907";
}
.ewse_instagram:before {
  content: "\e908";
}
.ewse_linkedin:before {
  content: "\e909";
}
.ewse_send:before {
  content: "\e90a";
}
.ewse_twitter:before {
  content: "\e90b";
}
.ewse_user:before {
  content: "\e90c";
}
.ewse_webdev:before {
  content: "\e90d";
}
.ewse_youtube:before {
  content: "\e90e";
}
